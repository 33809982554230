<template>
  <div>
    <b-overlay
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row class="match-height">
          <transition
          mode="out-in"
          >
          <b-col md="8">
            <b-card>
                <b-form @submit.prevent="create">
                  <b-row>

                    <!-- title -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Name')"
                        label-for="v-name"
                      >
                        <b-form-input
                          id="v-name"
                          :placeholder="$t('Name')"
                          v-model="form.name"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Shoppers -->
                    <b-col cols="12">
                      <h4>{{$t('Shoppers')}}</h4>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Show-Shoppers"
                          :checked="true"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.index_shoppers)"
                          name="permissions[]"
                          value="Show Shoppers"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Show Shoppers')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Show-Shoppers-Details"
                          :checked="true"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.show_shoppers)"
                          name="permissions[]"
                          value="Show Shoppers Details"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Show Shoppers Details')}}</span>
                    </b-col>
                    <!-- Categories -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Categories')}}</h4>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Show-Categories"
                          :checked="true"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.index_categories)"
                          name="permissions[]"
                          value="Show Categories"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Show Categories')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Create Categories"
                          :checked="true"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.create_categories)"
                          name="permissions[]"
                          value="Create Categories"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Create Category')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Edit Categories"
                          :checked="true"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.edit_categories)"
                          name="permissions[]"
                          value="Edit Categories"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Edit Category')}}</span>
                    </b-col>
                    <!-- Products -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Products')}}</h4>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Show-Products"
                          :checked="true"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.index_products)"
                          name="permissions[]"
                          value="Show Products"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Show Products')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Create-Product"
                          :checked="true"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.create_products)"
                          name="permissions[]"
                          value="Create Product"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Create Product')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Edit-Product"
                          :checked="true"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.edit_products)"
                          name="permissions[]"
                          value="Edit Product"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Edit Product')}}</span>
                    </b-col>
                    <!-- Orders -->
                    <b-col cols="12">
                      <hr>
                      <h4>{{$t('Orders')}}</h4>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Show-Orders"
                          :checked="true"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.index_orders)"
                          name="permissions[]"
                          value="Show Orders"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Show Orders')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Show-Order"
                          :checked="true"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.show_orders)"
                          name="permissions[]"
                          value="Show Order"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Show Order')}}</span>
                    </b-col>
                    <b-col cols="6">
                        <b-form-checkbox
                          id="v-Change-order-history"
                          :checked="true"
                          class="custom-control-primary"
                          @change="checkPermissions(permissions_ids.add_order_history)"
                          name="permissions[]"
                          value="Change order history"
                          switch
                          inline
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span class="font-weight-bold">{{$t('Change order history')}}</span>
                    </b-col>

                    
                    <!-- submit -->
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                      >
                        <template v-if="!isLoadingSave">
                          {{$t('Save')}}
                        </template>
                        <template v-if="isLoadingSave">
                          {{$t('Saveing')}}...
                          <b-spinner small />
                        </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
            </b-card>
          </b-col>
          </transition>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  name: 'RolesCreate',

  data() {
    return {
      isLoading: false,
      isLoadingSave: false,
      form:{
        permissions: [],
        name: ''
      },
      permissions_ids: {
        show_shoppers: 1,
        index_shoppers: 2,
        index_categories: 3,
        edit_categories: 4,
        create_categories: 5,
        index_products: 6,
        edit_products: 7,
        create_products: 8,
        index_orders: 9,
        show_orders: 10,
        add_order_history: 11,
        index_marketing_offers: 12,
        index_coupon: 13,
        edit_coupon: 14,
        create_coupon: 15,
        index_banners: 16,
        edit_banners: 17,
        create_banners: 18,
        index_featured_prooducts: 19,
        edit_featured_prooducts: 20,
        create_featured_prooducts: 21,
        show_reports: 22,
        show_store_info: 23,
        show_store_design: 24,
        show_payment_methods: 25,
        show_shipping_methods: 26,
        show_other_services: 27,
        show_users: 28,
        index_pages: 29,
        edit_pages: 30,
        create_pages: 31,
        index_subscraptions: 32,
        create_subscraptions: 33,
        index_mhd_store: 34,
      }
    }
  },
  mounted() {
  },
  methods: {
    create() {
      this.isLoadingSave = true;
      useJwt.post('/roles/store',this.form)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
        this.$router.push({ name: 'roles' })
      })
      .catch(error => {
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message.title)
      });
    },
    checkPermissions(permission){
      if (this.form.permissions.includes(permission)) {
        // remove
        this.form.permissions.splice(this.form.permissions.indexOf(permission), 1);
      } else {
        // add
        this.form.permissions[this.form.permissions.length] = permission
      }
      console.log(this.form.permissions)
    }
  }
}
</script>
<style lang="scss">

</style>
